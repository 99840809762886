import React, {useEffect, useState} from "react";
import {Button, Container, Form} from "react-bootstrap";
import {AdresseBdE} from "../authenticated/payment/OrdreBdECheque";

/**
 *
 * @param date string
 * @return age number
 */
export function calculeAge(date) {
    let birthday = new Date(date);
    let maintenant = new Date();
    return maintenant.getFullYear() - birthday.getFullYear()
}

/**
 *
 * @param props.bizuth
 * @param props.member
 * @returns {*}
 * @constructor
 */
export default function BizuthWeiForm(props) {
    const [regimeParticulier, setRegimeParticulier] = useState("");
    const [infosSpeciales, setInfosSpeciales] = useState("");
    const [ouiCheque, setOuiCheque] = useState(false);
    //const [dechargeFile, setDecharge] = useState(null);

    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (props.bizuth != null) {
            setRegimeParticulier(props.bizuth.regime_particulier === '' ? null : props.bizuth.regime_particulier);
            setInfosSpeciales(props.bizuth.informations_speciales === '' ? null : props.bizuth.informations_speciales);
            setOuiCheque(true);
            setFirstTime(false);
        } else {
            setFirstTime(true);
        }
    }, [props.bizuth]);

    return (<div>
        <Form onSubmit={(ev) => {
            ev.preventDefault();
            props.onSubmitted(regimeParticulier, infosSpeciales, /*dechargeFile*/null);
        }}>
            <Form.Group controlId="bizuthRegimeParticulier">
                <Form.Label>Régime particulier: (Exemple: végétarien, halal, ...)</Form.Label>
                <Form.Control type="text" value={regimeParticulier} onChange={(ev) => {
                    setRegimeParticulier(ev.target.value)
                }}/>
            </Form.Group>

            <Form.Group controlId="bizuthInfosSpeciales">
                <Form.Label>Informations particulères: (Exemple: maladie, alergie, ...)</Form.Label>
                <Form.Control type="text" value={infosSpeciales} onChange={(ev) => {
                    setInfosSpeciales(ev.target.value)
                }}/>
            </Form.Group>

            {/*
            <FormFile controlId="bizuthDecharge">
                <FormFile.Label>Décharge parentale (si mineur)</FormFile.Label>
                <FormFile.Input onChange={(ev) => {
                    setDecharge(ev.target.files[0]);
                    console.log(ev.target.files[0])
                }}/>
            </FormFile>
            */}

            <Form.Group controlid="ouiCheque">
                <Form.Check type="checkbox"
                            label="Je m’engage à envoyer le formulaire ainsi que les chèques de 70€ et de 100€ (caution non encaissée) à l’ordre du BdE INSA Lyon à l’adresse suivante"
                            feedback="acceptez" required checked={ouiCheque}
                            onChange={(ev) => setOuiCheque(ev.target.checked)}/>
            </Form.Group>
            <Container>
                <AdresseBdE/>
            </Container>

            <Button type="submit">{firstTime ? "Confirmer " : "Enregistrer"} mon inscription</Button>
            {props.child}
        </Form>
    </div>)
}
