import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button, InputGroup} from "react-bootstrap";
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import {bizuthDefaultDepartmentName, bizuthDefaultSchoolName, bizuthDefaultYearshort_name} from "../env";
import {handleAxiosError} from "../errors";

/**
 *
 * @param props.member : {} si vide, l'ojet sinon
 * @param props.editAllowed
 * @param props.onSubmitted fonction appelée à la validation
 */
export default function RegisterForm(props) { //initialiser member à {} s'il n'existe pas
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const password2Ref = useRef(null);

    const [phone, setPhone] = useState(props.member.phone || "");
    const [birthday, setBirthday] = useState(props.member.birthday || "");
    const [gender, setGender] = useState(props.member.gender ?? 'W');
    const [studentYear, setStudentYear] = useState(bizuthDefaultYearshort_name);
    const [studentDepartement, setStudentDepartement] = useState(bizuthDefaultDepartmentName);

    const [mdpValide, setMdpValide] = useState(true);

    const [tel, setTel] = useState(undefined);

    const [departmentList, setDepartmentList] = useState([]);

    useEffect(() => {
        window.httpService.get('/v1/study_department/').then(res => {
            if (res.data != null) {
                setDepartmentList(res.data);
            }
        }).catch(handleAxiosError);
    }, []);


    function formSubmit() {
        let member = {
            phone: phone,
            birthday: birthday,
            gender: gender,
            category: 'student',
            study_year: studentYear,
            student_profile: {
                department: studentDepartement,
                school: bizuthDefaultSchoolName,
                student_number: null, //bien null et pas '' pour l'unicité
                study_year: studentYear,
            },
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
        };

        console.log('submitted, ', member);
        props.onSubmitted(member);
    }

    function phoneValid() {
        if (phone !== "") {
            if (tel !== undefined) {
                if (tel.isValid()) {
                    return true;
                } else {
                }
            } else {
            }
        }
        return false
    }

    function phoneInvalid() {
        if (phone !== "") {
            if (tel !== undefined) {
                if (tel.isValid()) {
                    return false;
                } else {
                    return true
                }
            } else {
                return true;
            }
        }
        return false
    }

    return (<Form onSubmit={(ev) => {
        ev.preventDefault();
        formSubmit();
    }
    }>

        <Row className="show-grid">
            <Col md={4}>
                <Form.Group controlId="memberLastName">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control type="text"
                                  value={lastName}
                                  onChange={(ev) => {
                                      setLastName(ev.target.value)
                                  }} required/>
                </Form.Group>
                <Form.Group controlId="memberFirstName">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control type="text"
                                  value={firstName}
                                  onChange={(ev) => {
                                      setFirstName(ev.target.value)
                                  }} required/>
                </Form.Group>

                <Form.Group controlId="memberGender">
                    <Form.Label>Sexe</Form.Label>
                    <Form.Control as="select" value={gender} onChange={(ev) => {
                        setGender(ev.target.value)
                    }}>
                        <option value="W">Femme</option>
                        <option value="M">Homme</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="studentYear">
                    <Form.Label>Département INSA</Form.Label>
                    <Form.Control as="select" value={studentDepartement} onChange={(ev) => {
                        setStudentDepartement(ev.target.value)
                    }}>
                        {departmentList.map((department, index) => {
                            return (<option key={203 + index} value={department.short_name}>{department.name}</option>)
                        })}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="studentYear">
                    <Form.Label>Année INSA</Form.Label>
                    <Form.Control as="select" value={studentYear} onChange={(ev) => {
                        setStudentYear(ev.target.value)
                    }}>
                        {
                            departmentList !== null && departmentList.length > 0 && studentDepartement !== null ?
                                (departmentList
                                    .find(d => {
                                        return d.short_name === studentDepartement
                                    })
                                    .study_years
                                    .map((y, index) => {
                                        return (<option key={index * departmentList
                                            .find(d => {
                                                return d.short_name === studentDepartement
                                            })
                                            .id}>
                                            {y}
                                        </option>)
                                    })) : ""
                        }
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId="memberBirthday">
                    <Form.Label>Date de naissance</Form.Label>
                    <Form.Control type="date" value={birthday} required
                                  onChange={(ev) => {
                                      setBirthday(ev.target.value)
                                  }} isInvalid={!Boolean(birthday.match(/\d{4}-\d{2}-\d{2}/))}
                                  isValid={Boolean(birthday.match(/\d{4}-\d{2}-\d{2}/))}/>
                </Form.Group>

                <Form.Group controlId="memberPhone">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control type="phone" placeholder="+33xxxxxxxxxx"
                                  value={phone} onChange={(ev) => {
                        setTel(parsePhoneNumberFromString(ev.target.value.toString()));
                        setPhone(ev.target.value);
                        //ev.target.setCustomValidity((tel === undefined ? true : tel.isValid()) ? "" : "Votre numéro de téléphone n'est pas au bon format");
                    }} isValid={phoneValid()} isInvalid={phoneInvalid()}/>
                    <Form.Control.Feedback type="invalid">
                        Votre n° de téléphone doit etre au format international.
                    </Form.Control.Feedback>
                    <Form.Text>
                        Permet de vous contacter rapidement en cas de problème.
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col md={2}>
            </Col>
            <Col md={6}>
                <Form.Group controlId="memberEmail">
                    <Form.Label>Adresse e-mail</Form.Label>
                    <InputGroup>
                        <Form.Control type="email"
                                      value={email}
                                      onChange={(ev) => {
                                          setEmail(ev.target.value)
                                      }} required/>
                    </InputGroup>
                    <Form.Text>
                        Un mail de vérification vous sera envoyé.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="memberPassword">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control type="password"
                                  value={password}
                                  onChange={(ev) => {
                                      setPassword(ev.target.value);
                                      setMdpValide(checkKeycloakPasswordPolicy(ev.target.value));
                                      ev.target.setCustomValidity(checkKeycloakPasswordPolicy(ev.target.value) ? "" : "Erreur: Votre mot de passe doit faire au moins 9 caractères, être composé de chiffres, de lettres majuscules et minuscules.");

                                      if (password2Ref.current) {
                                          password2Ref.current.setCustomValidity(
                                              ev.target.value === password2
                                                  ? ""
                                                  : "Vos mots de passe ne correspondent pas"
                                          );
                                      }
                                  }} required isValid={checkKeycloakPasswordPolicy(password)}/>
                </Form.Group>
                <Form.Group  controlId="memberPassword2">
                    <Form.Label>Confirmation du mot de passe</Form.Label>
                    <Form.Control type="password"
                                  value={password2}
                                  ref={password2Ref}
                                  onChange={(ev) => {
                                      setPassword2(ev.target.value);
                                      ev.target.setCustomValidity((password === ev.target.value) ? "" : "Vos mots de passe ne correspondent pas");

                                  }} required isValid={password === password2 && checkKeycloakPasswordPolicy(password)}/>
                </Form.Group>
                <p>
                    Votre mot de passe doit faire au moins 9 caractères, être composé de chiffres, de lettres majuscules et
                    minuscules.
                </p>
            </Col>
        </Row>
        <Form.Group controlId="cgu">
            <Form.Check type="switch" custom>
                <Form.Check.Input type="checkbox" required/>
                <Form.Check.Label>
                    J'accepte les <a target="_blank" href="/CGU.pdf">conditions
                    générales d'utilisation <span style={{color: "blue"}}>(PDF)</span></a>
                </Form.Check.Label>
            </Form.Check>
        </Form.Group>
        <Button className="float-right" type="submit" disabled={!props.editAllowed}>Enregistrer</Button>
    </Form>)
}

/**
 * Votre mot de passe doit faire au moins 8 caractères, être composé de chiffres, de lettres majuscules et minuscules.
 * @param password string
 * @return ok boolean
 */
function checkKeycloakPasswordPolicy(password) {
    return Boolean(password.length > 8) && Boolean(password.match(/[A-Z]/)) && Boolean(password.match(/[a-z]/)) && Boolean(password.match(/[0-9]/));
}
