import React, {useEffect, useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

/**
 * @param props.membership , c'est l'id de l'adhésion qu'on va payer
 * @param props.errorHandler, la fonction à laquelle on donnera l'erreur http
 * @params props.child
 */
export default function PaymentComponent(props) {
    const [step, setStep] = useState(0);
    const [transaction, setTransaction] = useState(null);
    const [formData, setFormData] = useState(null);
    const [paymentURL, setPaymentURL] = useState(null);
    const [cgvAccepted, setCgvAcepted] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const cgvUrl = "/CGV.pdf";

    const membership_id = props.membership.id;
    const errorHandler = props.errorHandler;

    useEffect(() => {
        window.httpService.post('/up2pay/payment/', {
            membership: membership_id,
        }).then((res) => {
            setStep(1);
            setTransaction(res.data);
        }).catch(err => {
            errorHandler(err);
        });
        // eslint-disable-next-line
    }, []);

    function startUp2PayPayment() {
        if (cgvAccepted) {
            setStep(2);
            window.httpService.get('/up2pay/payment/' + transaction.id + '/pay/')
                .then((res) => {
                    setPaymentURL(res.data.url);
                    setFormData(res.data.form);
                    setStep(3);
                })
                .catch(err => {
                    props.errorHandler(err);
                });
        } else {
            handleShow();
        }
    }

    function up2payRedirect() {
        setTimeout(() => {
            document.getElementById('redirectForm').submit();
        }, 300);
    }

    return (<div>
        {step === 0 ? <div>
            <Spinner animation="border"/> Récupération du prix ...
        </div> : ""}

        {step === 1 && transaction !== null ? <div>
            Vous allez payer <strong>{transaction.amount / 100} €</strong>
            <p>Vous serez redirigé sur la plate-forme de paiement en ligne Up2Pay, de la banque Crédit Agricole.</p>
            <Form.Check type="checkbox" required checked={cgvAccepted}
                        onChange={(ev) => {
                            setCgvAcepted(ev.target.checked);
                            console.log(ev);
                        }} label={
                <p>J'accepte les <a target="_blank" rel="noopener nofollow noreferrer"
                                    href={cgvUrl} style={{color: "blue", textDecoration: "underline"}}>
                    conditions générales de vente (PDF)</a>
                </p>}/>
            <Button variant={cgvAccepted ? "success" : "danger"}
                    onClick={startUp2PayPayment}>Payer</Button> &nbsp; {props.child}
        </div> : ""}

        {step === 2 ? <div>
            <Spinner animation="border"/>Récupération des informations de paiement...
        </div> : ""}

        {step === 3 ? <div>
            {paymentURL !== null && formData !== null ? <div>
                    <Spinner animation="border"/> Redirection vers la plate-forme de paiement...
                    <form name="redirectForm" id="redirectForm" method="POST" action={paymentURL}>
                        {Object.keys(formData).map((key) => {
                            return <input type="hidden" name={key} value={formData[key]}/>
                        })}
                        <input type="submit" name="Go" value="Payer en ligne" className="btn"/>
                        {up2payRedirect()}
                    </form>
                </div> :
                <div>
                    Il y a eu un problème avec votre commande. Contactez le support
                    {props.child}
                </div>}
        </div> : ""}

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Accord requis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Vous devez accepter les <a style={{color: "blue", textDecoration: "underline"}} href={cgvUrl}>
                conditions générales de vente</a> pour poursuivre le paiement.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    handleClose();
                    setCgvAcepted(true);
                }}>Accepter les CGV</Button>
                <Button variant="secondary" onClick={handleClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)
}
