import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {bizuthDefaultDepartmentName, bizuthDefaultYearshort_name} from "../../env";
import {handleAxiosError} from "../../errors";

const emptyDepartment = '---';

/**
 *
 * @param props.member : {} si vide, l'ojet sinon
 */
export function MemberForm2(props) { //initialiser member à {} s'il n'existe pas
    const [phone, setPhone] = useState(props.member.phone);
    const [birthday, setBirthday] = useState(props.member.birthday);
    const [gender, setGender] = useState(props.member.gender ?? 'W');
    const [category, setCategory] = useState(props.member.category || 'student');
    const [depart, setDepart] = useState(props.member.student_profile ? props.member.student_profile.department : emptyDepartment);
    const [studyYear, setStudyYear] = useState(props.member.student_profile ? props.member.student_profile.department : emptyDepartment);
    /*const [depart, setDepart] = useState(props.member.student_profile ? props.member.student_profile.department : 'FIMI');
    const [studyYear, setStudyYear] = useState(props.member.student_profile ? props.member.student_profile.department : '1A');*/
    const [studentNumber, setStudentNumber] = useState(props.member.student_profile ? props.member.student_profile.student_number : "");

    const [departmentList, setDepartmentList] = useState([]);

    useEffect(() => {
        window.httpService.get('/v1/study_department/').then(res => {
            //res.data.push({id:0,short_name:null, name:"-----------"});
            if (res.data != null) {
                setDepartmentList(res.data);
                if (category === 'student') {
                    setTimeout(() => { //oui c'est moche mais je peux pas mettre la valeur par défaut car elle arrive après une requête
                        setDepart(bizuthDefaultDepartmentName);
                        setTimeout(() => setStudyYear(bizuthDefaultYearshort_name), 300);
                    }, 200);
                }
            }
        }).catch(handleAxiosError);
    }, [category]); //lancé au chargement

    function formSubmit() {
        let member = {
            phone: phone,
            birthday: birthday,
            gender: gender,
            category: category
        };
        if (member.category === 'student') {
            member.student_profile = {
                department: depart,
                study_year: studyYear,
                school: departmentList.find(d => d.short_name === depart).school,
                student_number: studentNumber !== '' ? studentNumber : null, //on ne veut pas de ''
            }
        } else {
            member.student_profile = null;
        }
        console.log('submitted, ', member);
        props.onSubmitted(member);
    }

    return (<Form onSubmit={(ev) => {
        ev.preventDefault();
        formSubmit();
    }
    }>
        <Form.Group controlId="memberPhone">
            <Form.Label>Numéro de téléphone</Form.Label>
            <Form.Control type="phone" placeholder="+33xxxxxxxxxx"
                          value={phone} onChange={(ev) => {
                setPhone(ev.target.value)
            }}/>
            <Form.Text className="text-muted">
                Permet de vous contacter rapidement en cas de problème.
            </Form.Text>
        </Form.Group>

        <Form.Group controlId="memberBirthday">
            <Form.Label>Date de naissance</Form.Label>
            <Form.Control type="date" value={birthday} required
                          onChange={(ev) => {
                              setBirthday(ev.target.value)
                          }}/>
        </Form.Group>

        <Form.Group controlId="memberGender">
            <Form.Label>Sexe</Form.Label>
            <Form.Control as="select" value={gender} onChange={(ev) => {
                setGender(ev.target.value)
            }}>
                <option value="W">Femme</option>
                <option value="M">Homme</option>
            </Form.Control>
        </Form.Group>

        <Form.Group controlId="memberCategory">
            <Form.Label>Catégorie</Form.Label>
            <Form.Control as="select" value={category} required
                          onChange={(ev) => setCategory(ev.target.value)}>
                <option value='student'>Étudiant</option>
                <option value='worker'>Employé INSA</option>
                <option value='other'>Autre</option>
            </Form.Control>
        </Form.Group>

        <Form.Group controlId="studentDepartment">
            <Form.Label>Département</Form.Label>
            <Form.Control as="select" value={depart}
                          onChange={(ev) => setDepart(ev.target.value)}
                          disabled={category !== 'student'} required={category === 'student'}>
                <option value={null}>{emptyDepartment}</option>
                {category === 'student' ? (departmentList.map((department, index) => {
                    return (<option key={203 + index} value={department.short_name}>{department.name}</option>)
                })) : ""}
            </Form.Control>
        </Form.Group>

        <Form.Group controlId="studentYear">
            <Form.Label>Année</Form.Label>
            <Form.Control as="select" onChange={(ev) => setStudyYear(ev.target.value)}
                          value={studyYear} disabled={category !== 'student'}
                          required={category === 'student'}>
                <option value={null}>{emptyDepartment}</option>
                {
                    departmentList !== null && departmentList.length > 0 && category === 'student' && depart !== emptyDepartment ?
                        (departmentList
                            .find(d => {
                                return d.short_name === depart
                            })
                            .study_years
                            .map((y, index) => {
                                return (<option key={index * departmentList
                                    .find(d => {
                                        return d.short_name === depart
                                    })
                                    .id}>
                                    {y}
                                </option>)
                            })) : ""
                }
            </Form.Control>

            <Form.Group controlId="studentNumber">
                <Form.Label>N° Étudiant</Form.Label>
                <Form.Control type="text" placeholder="36454654" value={studentNumber}
                              onChange={(ev) => setStudentNumber(ev.target.value)}
                              disabled={category !== 'student'}
                              required={category === 'student' && studyYear !== '1A'}/>
            </Form.Group>

            <Button type="submit" disabled={!props.editAllowed}>Enregistrer</Button>
        </Form.Group>
    </Form>)
}

export default class MemberForm extends React.Component {
    constructor(props) {
        super(props);
        this.phoneInput = React.createRef();
        this.birthdayInput = React.createRef();
        this.genderInput = React.createRef();
        this.member = props.member; //pour pas avoir de 'NullPointerException-like'
        this.state = {
            departments: [],
            depart: '------',
            year: 1,
            category: 'student',
            student_number: ''
        };
        if (this.member != null) {
            if (this.member.student_profile != null) {
                this.state['depart'] = props.member === null ? '------' : this.member.student_profile.department;
                this.state['year'] = props.member === null ? 1 : this.member.student_profile.study_year;
                this.state['student_number'] = props.member.student_profile.student_number;
            }
            this.state['category'] = props.member.category;
            //this.state['']
        } else {
            this.member = {} // pour éviter les erreurs
        }
    }

    componentDidMount() {
        this.phoneInput.current.focus();
        this.props.httpService.get('/v1/study_department/').then(res => {
            //res.data.push({id:0,short_name:null, name:"-----------"});
            this.setState({departments: res.data})
        }).catch(handleAxiosError);
    }

    formSubmit() {
        let studentProfile = null;
        if (this.state.category === 'student') {
            if (this.state.depart === '------') {
                return;
            }
            studentProfile = {
                study_year: this.state.year,
                department: this.state.depart,
                school: this.state.departments.find(d => d.short_name === this.state.depart).school,
                student_number: this.state.student_number
            };
        }
        if (this.member == null) {
            this.props.onSubmit({
                phone: this.phoneInput.current.value ? this.phoneInput.current.value : null,
                birthday: this.birthdayInput.current.value,
                gender: this.genderInput.current.value,
                student_profile: studentProfile,
                category: this.state.category
            });
        } else {
            this.member['phone'] = this.phoneInput.current.value ? this.phoneInput.current.value : null;
            this.member.birthday = this.birthdayInput.current.value;
            this.member.gender = this.genderInput.current.value;
            this.member.student_profile = studentProfile;
            this.props.onSubmit(this.member);
            this.member.category = this.state.category;
        }
    }

    //props.onSubmit
    render() {
        const optionsDeparts = this.state.departments.map(depart => <option key={depart.id}
                                                                            value={depart.short_name}>[{depart.short_name}] {depart.name}</option>);
        let years = [];
        if (this.state.depart !== '------') {
            let departement = this.state.departments
                .find(depart => depart.short_name === this.state.depart);
            if (departement != null) {
                years = departement.study_years.map(year => <option
                    key={10 * this.state.depart.length + year.id}>{year}</option>);
            }

        }
        return (
            <div>
                <Form onSubmit={(ev) => {
                    ev.preventDefault();
                    this.formSubmit();

                }}>
                    <Form.Group controlId="memberPhone">
                        <Form.Label>Numéro de téléphone</Form.Label>
                        <Form.Control type="phone" placeholder="+33xxxxxxxxxx" ref={this.phoneInput}
                                      defaultValue={this.member.phone}/>
                        <Form.Text className="text-muted">
                            Permet de vous contacter rapidement en cas de problème.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="memberBirthday">
                        <Form.Label>Date de naissance</Form.Label>
                        <Form.Control type="date" ref={this.birthdayInput} required
                                      defaultValue={this.member.birthday}/>
                    </Form.Group>
                    <Form.Group controlId="memberGender">
                        <Form.Label>Sexe</Form.Label>
                        <Form.Control as="select" ref={this.genderInput} defaultValue={this.member.gender}>
                            <option value="W">Femme</option>
                            <option value="M">Homme</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="memberCategory">
                        <Form.Label>Catégorie</Form.Label>
                        <Form.Control as="select" value={this.state.category} onChange={(ev) => {
                            this.setState({category: ev.target.value});
                            if (ev.target.value !== 'student') {
                                this.setState({depart: '------', year: null});
                            }
                        }} required>
                            <option value='student'>Étudiant</option>
                            <option value='worker'>Employé INSA</option>
                            <option value='other'>Autre</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="studentDepartment">
                        <Form.Label>Département</Form.Label>
                        <Form.Control as="select" value={this.state.depart} onChange={(ev) => {
                            this.setState({depart: ev.target.value})
                        }} disabled={this.state.category !== 'student'} required={this.state.category === 'student'}
                                      isInvalid={this.state.depart === '------'}>
                            <option>------</option>
                            {optionsDeparts}
                        </Form.Control>
                    </Form.Group>
                    <Button type="submit" disabled={!this.props.editAllowed}>Enregistrer</Button>

                    <Form.Group controlId="studentYear">
                        <Form.Label>Année</Form.Label>
                        <Form.Control as="select" value={this.state.year} onChange={(ev) => {
                            this.setState({year: ev.target.value})
                        }} disabled={this.state.depart === '------'} required={this.state.category === 'student'}>
                            {years}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="studentNumber">
                        <Form.Label>N° Étudiant</Form.Label>
                        <Form.Control type="text" placeHolder="36454654" value={this.state.student_number} onChange={
                            (ev) => this.setState({student_number: ev.target.value})
                        } disabled={this.state.category !== 'student'} required={this.state.category === 'student'}/>
                    </Form.Group>

                    <Form.Group controlid="acceptGCUD">
                        <Form.Check type="checkbox"
                                    label="J'accepte les conditions générales d'utilisation du BdE INSA Lyon"
                                    defaultChecked={this.props.member != null}
                                    feedback="acceptez" required/>
                    </Form.Group>


                    <Button type="submit" disabled={!this.props.editAllowed}>Enregistrer</Button>
                </Form>
            </div>
        );
    }
}
