import React, {useEffect, useState} from "react";
import {Link, Route} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";
import {apiUrl, authUrl} from "../env";
import './landing.css'
import Landing from "./Landing";
import Modal from "react-bootstrap/Modal";


function Intro() {
    const [showWei, setShowWei] = useState(false);
    const [showCarteVa, setShowCarteVa] = useState(false);
    const [showVA, setShowVA] = useState(false);

    //const [vaWpContent, setVaWpContent] = useState(null);
    /*useEffect(() => {
        axios.default.get("https://integration.bde-insa-lyon.fr/wp-json/wp/v2/pages/1723").then(
            (res) => setVaWpContent(res.data.content.rendered)
        ).catch((err) => console.log(err));
    }, []);*/

    //const [weiWpContent, setWeiWpContent] = useState(null);
    /*useEffect(() => {
        axios.default.get("url d'une page wp qui parle du Wei").then(
            (res) => setWeiWpContent(res.data.content.rendered)
        ).catch((err) => console.log(err));
    }, []);*/


    return (<div className="intro-main">
        <h2 className="titleText">Portail adhérent VA INSA Lyon</h2>
        <br/>
        <br/>
        <Row>
            <Col className="frosted intro-box">
                <h5>Qu'est-ce que la Vie Associative de l'INSA Lyon ?</h5>
                <p className="juste">L'INSA a une Vie Associative (dite VA) particulièrement développée ! C'est cette VA
                    qui anime ton campus toute l'année, et plus particulièrement les 130 associations qui existent au
                    sein de l'INSA Lyon.</p>
                {/*<p>Abrégée en VA, c'est l'organisme qui fédère les associations étudiantes de l'INSA Lyon, nombreuses et
                    dynamiques.
                    Il y en a pour tous les goûts, du sport amateur à la musique en passant par la technologie.</p>*/}
                <br/>
                <br/>
                <div className="intro-button-large-container">
                    <div className="intro-button-container">
                        <Button variant="danger" className="intro-button-btn" onClick={() => setShowVA(true)}>
                            En savoir plus sur la VA
                        </Button></div>
                </div>
            </Col>
            <Col className="frosted intro-box">
                <h5>
                    Que sont les cartes VA
                </h5>
                <p className="juste">La carte VAdhésion et la carte VAvantages te permettent de participer à la vie associative.
                    La carte VAdhésion te permet d'adhérer à n'importe quelle association du campus !
                    La carte VAvantages, elle, te donne : des réductions sur les évènements des associations (certains même gratuits !), accès aux différents services du Bureau des Élèves et plein d'autres choses encore !</p>
                <br/>
                <br/>
                <div className="intro-button-large-container">
                    <div className="intro-button-container">
                        <Button variant="danger" className="intro-button-btn" onClick={() => setShowCarteVa(true)}>
                            En savoir plus sur les carte VA
                        </Button>
                    </div>
                </div>
            </Col>
            <Col className="frosted intro-box">
                <h5>
                    Qu'est-ce que le Weekend d'Intégration (WEI) ?
                </h5>
                <p className="juste">
                    C'est le WeekEnd d'Intégration !
                    Plus communément appelé WEI, c'est un évènement phare de ton intégration dans l'école.
                    Le Bureau des Elèves te propose de vivre un Week-end mémorable avec plein d'Insaliens de ta
                    promotion.
                    Et nous te l'assurons : c'est dans la joie, la bonne humeur et dans le respect de tous !
                </p>
                <br/>
                <br/>
                <div className="intro-button-large-container">
                    <div className="intro-button-container">
                        <Button variant="danger" className="intro-button-btn" onClick={() => setShowWei(true)}>
                            En savoir plus sur le WEI
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
        <br/>
        <br/>
        <br/>

        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Link to="/registration">
                    <Button variant="danger" size="lg" className="bouton-rond blink">
                        Adhérer à la vie associative
                    </Button>
                </Link>
        </div>
        <br/><br/>

        <Modal show={showVA} onHide={() => setShowVA(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><h5>la Vie Associative</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="juste">La Vie Associative est une part indispensable de la vie des Insaliens.
                    Il existe plus de 130 associations sur le campus, pour satisfaire tous les goûts et les envies !
                    Elles participent à l'animation du campus et sont à l'origine de près de 200 évènements, tout au long de l'année.
                </p>
                <p className="juste">Tu peux les intégrer dès la première année, en fonction de ton temps libre, ou les années suivantes.
                Quoi qu'il en soit, même sans être membre d'une association, tu peux participer à ses évènements : mais pour cela tu auras besoins des cartes VA.
                </p>
                <p className="juste">
                    C'est sur ce site que tu pourras adhérer à la Vie Associative de l'école, pour la faire vivre et en profiter toute l'année ! Pour cela, regarde l'onglet "Qu'est-ce que les cartes VA ?".
                </p>
                Bonne route !
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowVA(false)}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showCarteVa} onHide={() => setShowCarteVa(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><h5>les Cartes VA</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="juste"> Bon maintenant que tu es convaincu que la Vie Associative c'est génial, comment y participer ?
                    Cela passe par les cartes VA : la carte VAdhésion et la carte VAvantages.
                    C'est grâce à ces participations que les associations peuvent proposer de si beaux événements ! En retour, ces cartes te permettent de bénéficier de nombreux privilèges.
                </p>
                <p>
                    La carte VAdhésion :
                </p>
                <ul>
                    <li>Indispensable pour adhérer à n’importe quelle association</li>
                    <li>Nominative, elle est valable toute l’année et quel que soit le nombre d’associations que tu souhaites rejoindre</li>
                    <li>Ton pass pour accéder à la K-Fêt, le bar étudiant du campus, qui organise des concerts, les Post-Partiels, etc...
                    </li>
                </ul>
                <p>
                    La carte VAvantages :
                </p>
                <ul>
                    <li>Tarifs très préférentiels sur tous les événements organisés par les associations du campus</li>
                    <li>Location de matériel au BdE (ex : des appareils à raclette, des crêpières, du matériel de sport…)</li>
                    <li>Accès à la COOP, épicerie située dans la Maison des Etudiants qui vend à des prix intéressants tout le nécessaire pour le quotidien d'un étudiant (alimentaire et autre)</li>
                    <li>Utilisation du parc photocopie du BdE</li>
                    <li>Et plein d'autres avantages encore que tu auras l’occasion de découvrir dès ton arrivée à l’INSA !</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowCarteVa(false)}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>


        <Modal show={showWei} onHide={() => setShowWei(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><h5>le Weekend d'Intégration</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src="/ZimbabWei.png" alt="bannière ZimbabWei" className="image-fix"/><br/>
                <p>Direction <b>Vias-Plage</b> les <b>5 & 6 octobre</b>, on t'embarque pour un week-end de folie
                    qui saura marquer ton esprit !</p>
                <p>Le Week-End d'Intégration, c'est la plus grande occasion pour toi de te faire des amis au début
                    de l'année ! C'est le dernier évènement de l'intégration, un moment de plus pour rencontrer les
                    personnes avec lesquelles tu passeras sans doute tes prochaines années à l'INSA.</p>

                <p>Organisé par le BdE depuis plus de 25 ans, le Week-End d'Intégration est une expérience
                    conviviale à ne rater sous aucun prétexte.</p>

                <h5>Ce qu'il t'attend</h5>

                <p className="juste">Nous partons direction Vias Plage, qui sera transformé en savane le temps d’un week-end.
                    Nichées entre deux océans, ses plages nous attendent avec impatience pour vous en mettre plein la vue.
                    Au programme : activités à la plage, à la piscine et au camping ! Inscris-toi vite pour ne pas manquer ça !</p>

                <h6>Au Programme</h6>
                Grâce à ton inscription au WEI, tu profiteras pour 70€ de tous ces avantages :
                <ul>
                    <li>Transport aller-retour en bus</li>
                    <li>Un camping 4 étoiles avec piscines avec toboggans et grand espace pour faire la fête!</li>
                    <li>Une nuits en bungalow</li>
                    <li>Les repas</li>
                    <li>Des tas d'activités !</li>
                </ul>
                <h6>Comment s'inscrire ?</h6>
                <p>
                    Si tu lis ceci, c'est que tu es sur la bonne voie ! Sur cette billetterie, tu peux trouver le pack
                    WEI + VA qui te permettra de venir passer un super week-end avec ta promo.
                </p>
                <p>
                    Pour cela, il faut compléter ton formulaire d'inscription, puis suivre les indications pour
                    effectuer le paiement.
                </p>
                N'oublie pas de confirmer ton inscription à la rentrée !

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowWei(false)}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>


    </div>)
}

function InfoComponent() {
    useEffect(() => {
        console.log("api url:", apiUrl);
        console.log("auth url:", authUrl);
        console.log("test direct", '__API_URL__');
    }, []);

    return (
        <div>
            <div className="bgparent">
                <div className="holibg"/>
                <div className="holifg">
                    <Route exact path="/tour" component={Landing}/>
                    <Route exact path="/tour/primo-arrivant" component={Intro}/>
                </div>
            </div>
        </div>
    );
}

export default InfoComponent;
