import React, {useEffect, useState} from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export function ErrorComponent() {
    const [shown, show] = useState(false);

    const [title, setTitle] = useState("Erreur!");
    const [detail, setDetail] = useState("");
    const [codeDetail, setCodeDetail] = useState("");
    useEffect(() => {
        // oui c'est pas bien mais je préfère avoir des fonctions pour les erreurs
        // au final j'ai recode alert("") en plus joli et plus pro
        window.showError = function (title, detail, codeDetail) {
            setTitle(title);
            setDetail(detail);
            setCodeDetail(codeDetail);
            show(true);
        };
    }, []);

    const [copie, setCopie] = useState(false);
    return (<Modal show={shown} onHide={() => show(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {detail}
            <br/>
            {codeDetail !== "" ? <Alert variant="warning">
                <p>Si vous nous contactez à propos de cette erreur, merci de fournir le texte ci-dessous.
                    Pour une meilleure prise en charge, veuillez copier/coller le texte et non prendre une capture
                    d'écran</p>
                <p>Le détail de l'erreur peut contenir vos informations personelles (email, mot de passe !), donc
                    ne postez pas ça en public sur Facebook !</p>
                <CopyToClipboard text={codeDetail.toString()}>
                    <div>
                        <pre><code>
                            {codeDetail.toString()}
                        </code></pre>
                        <Button onClick={() => setCopie(true)}>
                            {copie ? "copié !" : "Copier"}
                        </Button>
                    </div>
                </CopyToClipboard>
            </Alert> : ""}
        </Modal.Body>


        <Modal.Footer>
            <Button variant="secondary" onClick={() => show(false)}>
                Fermer
            </Button>
        </Modal.Footer>
    </Modal>)
}

export function showError(title = 'Erreur !', detail = "", codeDetail = "") {
    console.log("showing");
    window.showError(title, detail, codeDetail)
}

export function handleAxiosError(err) {
    console.log("erreur axios: ", err);

    if (err.response) {
        let detailCode = /*JSON.stringify(err.response.request)+'\n---\n'+*/JSON.stringify(err.response);
        console.log("detailCode: ", detailCode);
        if (err.response.status === 503 || err.response.status === 502 || err.response.status === 504) {
            showError("Erreur serveur", "Les serveurs du BdE ne sont pas accessibles en ce moment. Cela peut être dû à un problème, une erreur de configuration ou une opération de maintenance en cours.", detailCode);
        }
        if (err.response.status === 404) {
            showError("Erreur 404", "l'endpoint demandé n'a pas été trouvé sur le serveur. Cela peut signifier que vous n'avez pas les autorisations nécessaires pour effectuer cette action, que cette application est obsolète ou qu'elle n'est pas à jour.", detailCode)
        }

        if (400 <= err.response.status && err.response.status <= 499) {
            let title = "Erreur !";
            let detail = "";
            if (err.response.data) {
                console.log(err.response.data);

                let obj = err.response.data;
                detail = <JsonToHtml json={obj}/>
                if (JSON.stringify(obj) === '{"student_profile":{"study_year":["L\'object avec name=1A n\'existe pas."]}}') {
                    title = "L'inscription est désactivée, veuillez attendre le feu vert !"
                }
            }
            if (err.response.status === 401)
                title = "Échec d'authentification, essayez d'actualiser la page";
            showError(title, detail, detailCode);
        }
    } else if (err.request) { // client never received a response, or request never left
        showError("Erreur: Aucune connexion réseau", "votre navigateur n'a pas pu entrer en lien avec notre serveur. Vérifiez votre connexion internet.", "")
    } else {
        showError("Erreur inconnue !", "", "")
    }
}

export function Testf(props) {
    let json = {
        'obj': {
            'a': 1
        },
        'list': [
            'b',
            {'c': 3, d: 4},
            4
        ]
    }
    let err401 = {
        "detail": "Vous n'avez pas la permission d'effectuer cette action."
    }
    let fakeform = {
        "first_name": [
            "Ce champ est obligatoire."
        ],
        "last_name": [
            "Ce champ est obligatoire."
        ],
        "email": [
            "Ce champ est obligatoire."
        ],
        "gender": [
            "Ce champ est obligatoire."
        ],
        "password": [
            "Ce champ est obligatoire."
        ]
    }
    let classik = {
        "email": [
            "Un objet member avec ce champ email existe déjà."
        ]
    }
    return <div>
        <p>test JSON html</p>
        <JsonToHtml json={json}/>
        <hr/>
        <JsonToHtml json={err401}/>
        <hr/>
        <JsonToHtml json={fakeform}/>
        <hr/>
        <JsonToHtml json={classik}/>
    </div>
}

export function JsonToHtml(props) { //voir index.css
    switch (props.json.constructor) {
        case Array:
            return <ul className="error-detail">
                {props.json.map((val) => <li className="error-detail"><JsonToHtml json={val}/></li>)}
            </ul>
        case Object:
            return <>
                {Object.entries(props.json).map(item => <div className="error-detail">
                    <b className="error-detail">{item[0]}</b> <JsonToHtml json={item[1]}/>
                </div>)}
            </>
        default:
            return <>{props.json}</>
    }
}
