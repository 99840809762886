import React from "react";
import {Card} from "react-bootstrap";

/**
 *
 * @param props
 * @param props.bizuth
 * @param props.member
 * @param props.status
 * @param props.va_child
 * @param props.wei_child
 * @returns {JSX.Element}
 * @constructor
 */
export default function Recap(props) {
    return (<div>
        <br/>
        <Card>
            <Card.Body>
                <Card.Title>Vie Associative</Card.Title>
                {props.member === null ? "" :
                    <div>
                        Numéro d'adhérent : <strong>#{props.member.id}</strong> <br/>
                        Prénom : <strong>{props.member.first_name}</strong><br/>
                        Nom : <strong>{props.member.last_name}</strong><br/>
                        E-mail : <strong>{props.member.email}</strong><br/>
                        {props.member.phone === null ? "" : <span>
                            Téléphone: <strong>{props.member.phone}</strong>
                        </span>}
                    </div>}<br/>

                Étape de l'inscription : <strong>{props.status}</strong><br/>

                <strong>Prix: 65€</strong>

                {props.va_child !== null ? props.va_child : ""}
            </Card.Body>
            <br/>
        </Card>

        <br/>
        <Card>
            <Card.Body>
                <Card.Title>Weekend d'Intégration</Card.Title>
                {props.bizuth === null ? <div>
                    Non inscrit au Wei
                </div> : <div>
                    Étape de l'inscription : <strong>{props.bizuth.cancelled ? "Vous vous êtes désinscrit du WEI" :
                    <span>
                        {props.bizuth.a_paye ? "Vous êtes inscrit au WEI et vous avez payé" : "Inscrit en attente de paiement"}
                    </span>}</strong><br/>
                    <strong>Prix : 70€</strong>
                </div>}
                <br/>
                {props.wei_child !== null ? props.wei_child : ""}
            </Card.Body>
        </Card>

    </div>)
}
