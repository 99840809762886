import React from "react";

export function AdresseBdE(props) {
    return <blockquote className="blockquote text-center">
        BdE INSA Lyon - Équipe CoWei<br/>
        18 Avenue des arts<br/>
        69621 Villeurbanne CEDEX<br/>
        FRANCE<br/>
    </blockquote>
}
