const responseCodes = {
    "00000": "Transaction acceptée",
    "00001": "La connexion au centre d’autorisation a échoué ou une erreur interne est survenue.",
    "001": "Paiement refusé par le centre d’autorisation.",
    "00003": "Erreur de la plateforme.",
    "00004": "Numéro de porteur ou cryptogramme visuel invalide.",
    "00006": "Accès refusé ou site/rang/identifiant incorrect.",
    "00008": "Date de fin de validité incorrecte.",
    "00009": "Erreur de création d’un abonnement.",
    "00010": "Devise inconnue.",
    "00011": "Montant incorrect.",
    "00015": "Paiement déjà effectué.",
    "00016": "Abonné déjà existant (inscription nouvel abonné). Valeur ‘U’ de la variable PBX_RETOUR",
    "00021": "Carte non autorisée.",
    "00029": "Carte non conforme.",
    "00030": "Temps d’attente > 15 mn par l’internaute/acheteur au niveau de la page de paiements.",
    "00031": "Réservé",
    "00032": "Réservé",
    "00033": "Code pays de l’adresse IP du navigateur non autorisé.",
    "00040": "Opération sans authentification 3D-Secure, bloquée par le filtre.",
    "99999": "Opération en attente de validation par l’émetteur du moyen de paiement.",
    "AD1": "Erreur de sceau de la réponse client.",
    "AD2": "IPN non reçu bien que la transaction soit marquée comme acceptée par le retour client.",
    "AD3": "IPN non reçu et transaction non acceptée.",
}

const authCenterCodes = {
    "00": "Transaction approuvée ou traitée avec succès",
    "01": "Contacter l’émetteur de carte",
    "02": "Contacter l’émetteur de carte",
    "03": "Commerçant invalide",
    "04": "Conserver la carte",
    "05": "Ne pas honorer",
    "07": "Conserver la carte, conditions spéciales",
    "08": "Approuver après identification du porteur",
    "10": "Approuvé pour un montant partiel",
    "12": "Transaction invalide",
    "13": "Montant invalide",
    "14": "Numéro de porteur invalide",
    "15": "Emetteur de carte inconnu",
    "17": "Annulation client",
    "19": "Répéter la transaction ultérieurement",
    "20": "Réponse erronée (erreur dans le domaine serveur)",
    "21": "Pas d'action réalisée",
    "24": "Mise à jour de fichier non supportée",
    "25": "Impossible de localiser l’enregistrement dans le fichier",
    "26": "Enregistrement dupliqué, ancien enregistrement remplacé",
    "27": "Erreur en « edit » sur champ de mise à jour fichier",
    "28": "Accès interdit au fichier",
    "29": "Mise à jour de fichier impossible",
    "30": "Erreur de format",
    "31": "Identifiant de l'organisme acquéreur inconnu",
    "32": "Réalisé partiellement",
    "33": "Carte expirée",
    "34": "Suspicion de fraude",
    "38": "Nombre d’essais code confidentiel dépassé",
    "41": "Carte perdue",
    "43": "Carte volée",
    "51": "Provision insuffisante ou crédit dépassé",
    "54": "Date de validité de la carte dépassée",
    "55": "Code confidentiel erroné",
    "56": "Carte absente du fichier",
    "57": "Transaction non permise à ce porteur",
    "58": "Transaction interdite au terminal",
    "59": "Suspicion de fraude",
    "60": "L’accepteur de carte doit contacter l’acquéreur",
    "61": "Dépasse la limite du montant de retrait",
    "63": "Règles de sécurité non respectées",
    "68": "Réponse non parvenue ou reçue trop tard",
    "75": "Nombre d’essais code confidentiel dépassé",
    "76": "Porteur déjà en opposition, ancien enregistrement conservé",
    "89": "Echec de l’authentification",
    "90": "Arrêt momentané du système",
    "91": "Emetteur de cartes inaccessible",
    "94": "Demande dupliquée",
    "96": "Mauvais fonctionnement du système",
    "97": "Echéance de la temporisation de surveillance globale",
    "98": "Serveur inaccessible (positionné par le serveur)",
    "99": "Incident domaine initiateur",
    "A0": "Fallback en mode contact",
    "A1": "Soft Decline - Transaction déclinée pour absence d'authentification",
    "A4": "Mauvais usage de l'exemption TRA",
    "R1": "Révocation de tous les paiements récurrents pour cette carte chez ce marchand",
    "R3": "Révocation de tous les paiements récurrents pour cette carte",
    "A2": "PIN request in single TAO mode",
    "A3": "New TAP with required authentication"
}

export function getResponseCodeDescription(code) {
    if(code && code.startsWith("001")) {
        return responseCodes["001"] + " (" + authCenterCodes[code.substring(3)] + ")";
    }
    return responseCodes[code] ?? "Code inconnu : " + code;
}
