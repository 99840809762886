import React, {useEffect, useState} from "react";
import {Alert, Button, Fade, Nav, ProgressBar} from "react-bootstrap";
import Recap from "./Recap";
import BizuthWeiForm from "./BizuthWeiForm";
import Modal from "react-bootstrap/Modal";
import {AdresseBdE} from "../authenticated/payment/OrdreBdECheque";
import {handleAxiosError, showError} from "../errors";
import PaymentComponent from "../authenticated/payment/PaymentComponent";
import {getResponseCodeDescription} from "../authenticated/payment/codes";
import PDFiller from "./PDFAutoFiller";
import './BizuthComponent.css'
//TODO: utiliser des routes avec '/1' pour les différentes étapes
const status_initial = "non inscrit";
const status_awaitpayment = 'Inscrit en attente de paiement';
const status_awaitpayment_stand = 'Inscrit en attente de paiement sur place';
const status_vapayed = 'Inscrit et payé';

/*function PaiementChequeCovid(props) {
    return (<Alert variant="warning">
        <Alert.Heading>
            Attention
        </Alert.Heading>
        <p>En raison des circonstances liées au COVID-19, le paiement sera effectué <b>uniquement en chèque</b> pour
            faciliter
            les éventuels remboursements, notamment en cas d'annulation du Wei.</p>
    </Alert>)
}*/
const STEP_RECAP_INIT = 0;
const STEP_PAIEMENT_VA = 1;
const STEP_PAIEMENT_RETURN = 2;
const STEP_FORMULAIRE_WEI = 3;
const STEP_PAIEMENT_WEI = 4;


export default function BizuthFlow(props){

    const [step, setStep] = useState(0); // étapes: 0->récap initial 1-> formulaire bizuth, 2->second recap
    const [member, setMember] = useState(null);
    const [bizuth, setBizuth] = useState(null);
    const [membership, setMembership] = useState(null);
    const [status, setStatus] = useState(status_initial);
    const [loading, setLoading] = useState(false);

    const [fade, setFade] = useState(false);

    const [showHelp, helpMe] = useState(window.localStorage.getItem("hideinfos") !== "true"); //affiche le popup d'infos
    const [showPaiementsurplace, setShowPaiementsurplace] = useState(false); //affichage des infos ...

    const [paymentErrorCode, setPaymentErrorCode] = useState(null);  //affiche un modal si nécessaire

    function handleError(err){
        handleAxiosError(err);
        setLoading(false);
    }

    function animateFade(){
        setFade(false);
        setTimeout(() => setFade(true), 200);
    }

    /*function registerWei() {
        if (bizuth === null) {
            setLoading(true);
            window.httpService.post('/wei/bizuth/', {}).then(res => {
                setLoading(false);
                setBizuth(res.data);
                animateFade();
                setStep(STEP_FORMULAIRE_WEI);
            }).catch(handleError);
        } else {
            animateFade();
            setStep(STEP_FORMULAIRE_WEI);
        }
    }*/

    function changeWei(regime_particulier, informations_speciales, file_decharge){
        if(member === null){
            return;
        }
        setLoading(true);
        let data = {
            regime_particulier: regime_particulier,
            informations_speciales: informations_speciales,
            cancelled: false
        }

        function weireturn(res){
            setLoading(false);
            setBizuth(res.data);
            animateFade();
            setStep(STEP_PAIEMENT_WEI);
        }

        if(bizuth === null){
            window.httpService.post('/wei/bizuth/', data).then(weireturn).catch(handleError);
        }else{
            window.httpService.patch('/wei/bizuth/' + bizuth.id + '/', data).then(weireturn).catch(handleError);
        }

        if(file_decharge !== null){
            const form = new FormData();
            form.append("file", file_decharge);
            window.httpService.put('/wei/decharge/', form).then(() => setLoading(false));
        }
    }

    /*function cancelWei() {
        if (bizuth !== null) {
            setLoading(true);
            window.httpService.delete('/wei/bizuth/' + bizuth.id + '/').then(res => {
                setLoading(false);
                setBizuth(null);
                animateFade();
                setStep(STEP_PAIEMENT_RETURN);
                console.log("bizuth null : (?)", bizuth);
            });
        } else {
            animateFade();
            setStep(STEP_PAIEMENT_RETURN);
        }
    }*/

    function joinVaUp2Pay(){
        setLoading(true);
        window.httpService.post('/customer/joinva/', {
            payment_method: 4
        }).then((res) => {
            setLoading(false);
            setMembership(res.data);
            animateFade();
            setStep(STEP_PAIEMENT_VA);
        }).catch(handleError);
    }

    function closePaiementSurPlaceInfoModal(){
        setShowPaiementsurplace(false);
    }

    function closePopupAide(){
        helpMe(false);
        window.localStorage.setItem("hideinfos", "true");
    }

    useEffect(() => { // exécuté au chargement de la page, récup les infos
        setLoading(true);
        setMembership(null); //pour rendre React content
        let urlParams = new URLSearchParams(window.location.search);
        let paymentSuccess = null;
        if(urlParams.get('code') === "00000")
            paymentSuccess = true;
        else if(urlParams.get('code') !== "null" && urlParams.get('code') !== undefined && urlParams.get('code') !== null){
            paymentSuccess = false;
            setPaymentErrorCode(urlParams.get('code'));
        }
        window.httpService.get('/customer/member/')
            .then(res => {
                if(res.data.length === 1){
                    const member = res.data[0];
                    setStatus(status_awaitpayment)
                    console.log(member);
                    setMember(member);
                    setBizuth(member.bizuth);
                    setLoading(false);
                    /*if (res.data[0].has_valid_membership) //récup la dernière adhésion valide
                        setMembership(res.data[0].memberships.filter(m => m.valid).sort((m1, m2) => m2.id - m1.id)[0] || null);*/

                    // Redirect non 1A FIMI students to the joinva page
                    if(member.student_profile?.study_year !== '1A' || member.student_profile?.department !== 'FIMI'){
                        props.history.replace('/authenticated/joinva' + window.location.search);
                        return;
                    }

                    if(member.has_valid_membership || member.va_cheque_received)
                        setStatus(status_vapayed);
                    else{
                        if(paymentSuccess === null)
                            setStatus(status_awaitpayment_stand);
                    }
                    if(paymentSuccess === false){
                        setStep(STEP_RECAP_INIT);
                    }else{
                        if(member.bizuth !== null){ // inscrit au WEI
                            setStep(STEP_PAIEMENT_WEI)
                        }else{
                            if(member.has_valid_membership || member.va_cheque_received)
                                setStep(STEP_PAIEMENT_RETURN)
                            else
                                setStep(STEP_RECAP_INIT);
                        }
                    }
                }else{
                    showError("Erreur de logique", "Vous n'avez pas de dossier adhérent relié à vos identifiants", "no member for user")
                }
            }).catch(handleError);
    }, []);
    useEffect(() => {
        if(member == null){
            setStep(-1);
            return;
        }
        /*if (status === status_vapayed && step < STEP_PAIEMENT_RETURN) {
            setStep(STEP_PAIEMENT_RETURN);
            console.log("ligne 171");
            return;
        }*/
        animateFade();
    }, [step, member, status]);
    return (<div>
        <Nav variant="pills" activeKey={step} justify="center" className="stepper">
            <Nav.Item>
                <Nav.Link style={{cursor: "default"}} eventKey={STEP_RECAP_INIT}>1</Nav.Link><p>Accueil</p>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey={STEP_PAIEMENT_VA}>2</Nav.Link><p>Paiement adhésion</p>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link style={{cursor: "default"}} eventKey={STEP_PAIEMENT_RETURN}>3</Nav.Link><p>Récapitulatif</p>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link style={{cursor: "default"}} disabled={bizuth != null ? bizuth.cancelled : false}
                          eventKey={STEP_FORMULAIRE_WEI}>4</Nav.Link><p>WEI</p>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link style={{cursor: "default"}} eventKey={STEP_PAIEMENT_WEI}>5</Nav.Link><p>Paiement WEI</p>
            </Nav.Item>
        </Nav>
        <ProgressBar variant="primary" className={loading ? "visible" : "invisible"} animated now={30}/>
        <br/>
        {step === STEP_RECAP_INIT ? <Fade in={fade}>
            <div>
                <Recap bizuth={bizuth} member={member} status={status} va_child={
                    <div>
                        <br/>&nbsp;
                        {(new Date().getTime() >= new Date('June 28, 2024 01:00:00').getTime()) &&
                            <>
                                <Button variant="success" onClick={joinVaUp2Pay}>
                                    Payer l'adhésion par Internet
                                </Button>&nbsp;&nbsp;
                            </>
                        }
                        <Button variant="primary" onClick={() => {
                            setStep(STEP_PAIEMENT_VA);
                            setStatus(status_awaitpayment_stand);
                            setMembership(null);
                            animateFade();
                        }}>Payer plus tard sur place (en espèces, chèque ou
                            carte bleue)</Button>
                    </div>
                }/>
            </div>
        </Fade> : ""}
        {step === STEP_PAIEMENT_VA && membership !== null ? <Fade in={fade}>
            <div>
                {membership.payment_method === 4 ? <div>
                    <h5>Paiement en ligne</h5>
                    Prix: <strong>{membership.membership.price_ttc} €</strong>
                    <PaymentComponent membership={membership} errorHandler={handleError}
                                      child={<Button variant="secondary" onClick={() => {
                                          setStep(STEP_RECAP_INIT);
                                          animateFade();
                                      }}>Annuler</Button>
                                      }/>
                </div> : <Alert variant="danger">
                    <Alert.Header>Erreur de logique</Alert.Header>
                    vous avez commencé le paiement en ligne mais le moyen de paiement choisi
                    n'est pas le moyen de paiement "Up2Pay en ligne"
                </Alert>}

            </div>
        </Fade> : ""}

        {step === STEP_PAIEMENT_VA && membership === null ? <Fade in={fade}>
                <div>
                    <p>Veuillez remplir le formulaire papier</p><br/><br/>
                    <PDFiller config={member} wei={bizuth !== null}/> &nbsp; <a href="/formulaire-inscription.pdf"
                                                                                target="_blank" rel="noopener noreferrer"
                                                                                style={{color: "blue"}}>formulaire
                    vierge</a><br/><br/>
                    <p>Veuillez envoyer le chèque d’adhésion de 65€ à l’ordre du BdE INSA Lyon avec votre nom au dos à
                        l’adresse suivante:</p>
                    <AdresseBdE/>

                    <Alert variant="info">
                        <p>Une fois ces différents éléments envoyés, votre inscription sera validée à la chaîne de
                            rentrée
                            de l’INSA Lyon, et votre carte d'adhérent vous sera remise.</p>
                    </Alert>
                    <Button onClick={() => {
                        setStep(STEP_PAIEMENT_RETURN);
                        animateFade();
                    }}>Continuer</Button>
                </div>
            </Fade>
            : ""}

        {step === STEP_PAIEMENT_RETURN ? <Fade in={fade}>
            <div>
                <Recap bizuth={bizuth} member={member} status={status} va_child={status !== status_vapayed ? <div>
                    <Button variant="info" onClick={() => setStep(STEP_RECAP_INIT)}>Modifier le moyen de
                        paiement</Button>
                </div> : ""}
                       wei_child={<div>
                           {new Date().getTime() >= new Date('July 1, 2024 01:00:00').getTime() ?
                               <>{bizuth === null ?
                                   <Button variant="success" onClick={() => {
                                       animateFade();
                                       setStep(STEP_FORMULAIRE_WEI);
                                   }}>
                                       M'inscrire au WEI
                                   </Button>
                                   : (bizuth.cancelled ? "" :
                                       <Button variant="success" onClick={() => {
                                           animateFade();
                                           setStep(STEP_FORMULAIRE_WEI)
                                       }}>Modifier le WEI</Button>)}</>
                               : <h5>Complet</h5>}
                           &nbsp; &nbsp;
                           {/*bizuth !== null ? <Button variant="warning" onClick={() => {
                               cancelWei();
                           }}>Me désinscrire du Wei</Button> : ""*/}
                       </div>}/>
                <br/>
                <hr/>
                <p>Il n'est pas permis de s'inscrire au Weekend d'Intégration sans adhérer à la Vie Associative</p>
                {status !== status_vapayed ? <Alert variant="warning">
                    <div>
                        <p>N'oubliez pas de remplir le formulaire papier et d'envoyer le chèque d’adhésion
                            de 65€ à l’ordre du BdE INSA Lyon avec votre nom au dos à l’adresse suivante
                        </p><br/><br/>
                        <PDFiller config={member} wei={bizuth !== null}/> &nbsp; <a href="/formulaire-inscription.pdf"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{color: "blue"}}>formulaire
                        vierge</a>
                    </div>
                    <br/>
                    <AdresseBdE/>
                </Alert> : ""}
            </div>
        </Fade> : ""}

        {step === STEP_FORMULAIRE_WEI ? <Fade in={fade}>
            <div>
                <Alert variant="info">
                    <p>Le Week-End d’Intégration, la plus grande occasion pour toi de te faire des amis au début de
                        l’année. C’est le dernier évènement de l’intégration, organisé par le BdE depuis maintenant 30
                        ans.
                        Tu pourras retrouver toutes les informations nécessaires sur le site de parrainage
                        <a target="_blank" rel="noopener noreferrer"
                           href="https://integration.bde-insa-lyon.fr">integration.bde-insa-lyon.fr</a>
                        Si tu as des questions, n’hésite pas à les poser soit sur le groupe Facebook de la promo 67,
                        soit par message à la page Facebook WEI – INSA Lyon, soit par e-mail à wei@bde-insa-lyon.fr.</p>
                </Alert>
                <Alert variant="warning">
                    <p>
                        Pour t’inscrire nous avons besoin de quelques informations supplémentaires.
                        Il est notamment nécessaire que vous remplissiez ce formulaire et de l’envoyer avec les chèques
                        associés au BdE INSA Lyon
                    </p>
                </Alert>
                {<BizuthWeiForm bizuth={bizuth} onSubmitted={changeWei} setLoading={setLoading} member={member}
                    /*child={<Button variant="link" onClick={cancelWei}>Annuler mon inscription au
             WEI</Button>}*//>}
            </div>
        </Fade> : ""}


        {step === STEP_PAIEMENT_WEI ? <Fade in={fade}>
            <div>
                <p>Attention, pour valider ton inscription au Wei n’oublie pas d’envoyer à </p>
                <AdresseBdE/>
                <ul>
                    <li>
                        Un chèque de paiement de 70€ à l’ordre du BdE INSA Lyon
                    </li>
                    <li>
                        Un chèque de caution de 100€ (non encaissé) à l’ordre du BdE INSA Lyon
                    </li>
                    <li>le formulaire d'inscription<br/><br/>
                        <PDFiller config={member} wei={bizuth !== null}/> &nbsp; <a href="/formulaire-inscription.pdf"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{color: "blue"}}>
                            formulaire vierge</a></li>
                </ul>
                {status !== status_vapayed ?
                    <p>N'oublie pas non plus le chèque de 65€ pour payer ton adhésion !</p> : ""}
                <Recap bizuth={bizuth} member={member} status={status} wei_child={<div>
                    <Button variant="info" onClick={() => {
                        animateFade();
                        setStep(STEP_FORMULAIRE_WEI)
                    }}>Modifier le WEI</Button>
                    &nbsp; &nbsp;
                    {/*<Button variant="warning" onClick={() => {
                        cancelWei();
                    }}>Me désinscrire du Wei</Button>*/}
                </div>} va_child={status !== status_vapayed ? <div>
                    <Button variant="success" onClick={() => setStep(STEP_RECAP_INIT)}>Modifier le moyen de
                        paiement</Button>
                </div> : ""}
                />
            </div>
        </Fade> : ""}

        <Modal show={showHelp} onHide={closePopupAide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Suite de l'inscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Bonjour futur(e) Insalien(ne) !</h6>
                <p>
                    Tu vas arriver à présent sur l'interface "Inscription Vie Associative".
                    Ce site te permettra de consulter ton profil VA et de t'assurer que les informations que tu as
                    remplies juste avant sont correctes.
                    Tu vas pouvoir alors choisir entre t'inscrire à la VA et au WEI, ou seulement à la VA, ou ne rien
                    faire.
                </p><p>
                Comme tu l'as probablement lu, l'adhésion à la Vie Associative a un certain coût (65€), et le WEI
                également (70€).
                {/*À ce stade, tu es d'ores et déjà "inscrit en attente de paiement" à la Vie Associative.
                Tu vas pouvoir ici t'inscrire an WEI, mais également de procéder au paiement en ligne si tu le
                souhaites.
                Payer en ligner te permet de rendre plus rapide la chaîne administrative à la rentrée, et surtout rend
                plus simple pour nous,
                le Bureau des Eleves, la gestion de ces adhésions. Cependant, tu n'es en aucun cas obligé(e) de payer en
                ligne.
                Tu peux également, si cela t'arrange, procéder au paiement seulement à la rentrée lors de cette même
                chaîne administrative.*/}
            </p>
                <p>
                    Quoi qu'il en soit, prend le temps de découvrir le WEI et la Vie Associative ! Ce sont des éléments
                    particulièrement marquants de ta vie d'étudiant !
                    Nous avons hâte de t'accueillir en Septembre prochain !
                </p>
                Le Bureau des Élèves
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePopupAide}>
                    Suivant
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showPaiementsurplace} onHide={closePaiementSurPlaceInfoModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Paiement sur place</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Vous avez décidé de procéder au paiement sur place lors de la chaîne de rentrée.</p>
                <p>
                    Votre inscription est confirmée, mais celle-ci sera annulée si vous ne venez pas la régler lors de
                    cette chaine de rentrée en Septembre. Sachez que vous pouvez à tout moment choisir de payer en ligne
                    en vous reconnectant sur la plateforme Portail VA.
                    Vous étant inscrit au WEI, il vous faut cependant ne pas oublier d'envoyer votre chèque de caution
                    et votre décharge parentale si vous êtes mineur.
                </p>
                <p>
                    Vous pouvez à présent télécharger votre billet. Celui-ci fait part de votre statut actuel et vous
                    permettra de simplifier la chaine de rentrée administrative. Veuillez penser à être en sa possession
                    lors de votre arrivée sur le campus en Septembre !
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePaiementSurPlaceInfoModal}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={paymentErrorCode !== null} onHide={() => setPaymentErrorCode(null)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Erreur de paiement en ligne</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Le paiement en ligne a échoué pour la raison suivante
                : <b>{getResponseCodeDescription(paymentErrorCode)}</b>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setPaymentErrorCode(null)}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    </div>);
}
