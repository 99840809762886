import React, {useEffect, useState} from 'react';
import {PDFDocument, rgb} from 'pdf-lib';
import {Button, Spinner} from "react-bootstrap";

//const url = 'https://adhesion.asso-insa-lyon.fr/formulaire-inscription.pdf'
const url = '/formulaire-inscription.pdf'

const color = rgb(0, 0, 0.4);
/**
 *
 * @param props.config
 * @param props.wei
 */
export default function PDFiller(props) {
    const [pdfHref, setPdfHref] = useState(null);

    const config = props.config;
    const wei = props.wei;

    function fillPdf() {
        fetch(url).then(res => {
            res.arrayBuffer().then((ab) => {
                PDFDocument.load(ab).then(pdfdoc => {
                    const page = pdfdoc.getPage(0);
                    const {width, height} = page.getSize()

                    if (config.gender === 'W')
                        page.drawText('X', { // femme
                            x: width * (574 / 827),
                            y: height * (1 - (623 / 1170)),
                            size: 20,
                            color: color
                        })
                    else
                        page.drawText('Y', { // homme
                            x: width * (500 / 827),
                            y: height * (1 - (623 / 1170)),
                            size: 20,
                            color: color
                        })

                    page.drawText(config.first_name, { // prénom
                        x: width * (520 / 827),
                        y: height * (1 - (636 / 1170)),
                        size: 12,
                        color: color
                    })
                    page.drawText(config.last_name, { // nom
                        x: width * (500 / 827),
                        y: height * (1 - (657 / 1170)),
                        size: 12,
                        color: color
                    })
                    page.drawText(new Date(config.birthday).toLocaleDateString('fr-FR'), { // date de naissance
                        x: width * (580 / 827),
                        y: height * (1 - (678 / 1170)),
                        size: 12,
                        color: color
                    })
                    page.drawText(config.phone || '', { // tel
                        x: width * (550 / 827),
                        y: height * (1 - (721 / 1170)),
                        size: 12,
                        color: color
                    })
                    page.drawText(config.email, { // email
                        x: width * (510 / 827),
                        y: height * (1 - (742 / 1170)),
                        size: 12,
                        color: color
                    })

                    if (wei)
                        page.drawText('X', { // va+wei
                            x: width * (84 / 827),
                            y: height * (1 - (757 / 1160)),
                            size: 20,
                            color: color
                        })
                    else
                        page.drawText('X', { // va
                            x: width * (84 / 827),
                            y: height * (1 - (730 / 1160)),
                            size: 20,
                            color: color
                        })

                    pdfdoc.save().then((data) => { // promise hell, merci /sfrenot
                        let blob = new Blob([data], {
                            type: 'application/pdf'
                        })
                        let url = window.URL || window.webkitURL;
                        //setTimeout(()=>setPdfHref(url.createObjectURL(blob)), 1500) // c'est trop rapide mdrr
                        setPdfHref(url.createObjectURL(blob))
                    }, err => console.log)
                })
            })

        })
    }

    useEffect(fillPdf, [config, wei])
        if (pdfHref === null) {
            return (<Button disabled="disabled"
                            variant='warning'>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                />
                remplissage du PDF
            </Button>)
        } else {
            return (<Button href={pdfHref} download="formulaire-inscription_pre-rempli.pdf"
                            variant='primary'>
                télécharger PDF pré-rempli
            </Button>);

        }
    }

/*
            <DemoPdf config={{
                gender: 'W',
                first_name: "Luthien",
                last_name: "Tinùviel",
                birthday: "1234/12/31",
                phone: "057460546055",
                email: "luthien.tinuviel@insa-lyon.fr",
            }} wei={true}/>
 */
