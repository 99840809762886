import React, {useEffect, useState} from "react";
import Konami from 'react-konami-code';
import './Dev.css'
import {Link} from "react-router-dom";

// https://www.w3schools.com/howto/howto_js_draggable.asp
function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "-header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "-header").onmousedown = dragMouseDown;
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }
}

export default function DevAuthenticator() {
    const [u, su] = useState("");
    const [p, sp] = useState("");

    const [haha, sa] = useState(false);

    const [easterSet, setEasteregg] = useState(false);
    const [dev, setDev] = useState(false);

    function authenticateDev() {
        window.localStorage.setItem('basictoken', window.btoa(u + ':' + p))
        hideDev();
    }

    function disconnectDev() {
        window.localStorage.removeItem('basictoken');
        hideDev();
    }

    function hideDev() {
        setEasteregg(false);
    }

    useEffect(() => {
        if (easterSet) {
            dragElement(document.getElementById("98window"));
        }
    }, [easterSet]);
    useEffect(() => {
        if (haha) {
            dragElement(document.getElementById("taskfailedsuccessfully"));
        }
    }, [haha]);
    useEffect(() => {
        window.devMode = dev;
        console.log(dev)
    }, [dev]);

    if (easterSet) {
        return (<div>
            <link
                rel="stylesheet"
                href="https://unpkg.com/98.css"
                type="text/css"
            />

            <div className="easteregg-main"/>

            <div className="window easteregg-window" id="98window">
                <div className="title-bar" id="98window-header">
                    <div className="title-bar-text">Outils de développement</div>
                    <div className="title-bar-controls">
                        <button aria-label="Close" onClick={hideDev}/>
                    </div>
                </div>
                <div className="window-body">
                    <fieldset className="fieldset-center">
                        <legend className="fieldset-legend">Authentification Basic
                        </legend>
                        <div className="field-row">
                            <label htmlFor="u">username/email</label>
                            <input id="u" type="text" value={u} onChange={(ev) => su(ev.target.value)}/>
                        </div>
                        <div className="field-row">
                            <label htmlFor="p">Mot de passe &nbsp; &nbsp;&nbsp;</label>
                            <input id="p" type="password" value={p} onChange={(ev) => sp(ev.target.value)}/>
                        </div>
                        <br/>
                        <button onClick={authenticateDev}>Valider</button>
                        &nbsp;
                        {window.localStorage.getItem('basictoken') === null ?
                            <button disabled>Déconnecter</button> :
                            <button onClick={disconnectDev}>Déconnecter</button>}

                    </fieldset>
                    <br/>
                    <button onClick={() => sa(true)}>Éteindre le serveur</button>
                    &nbsp;
                    <button onClick={() => {
                        document.location.replace("https://www.youtube.com/watch?v=dQw4w9WgXcQ")
                    }}>Rendre le WEI gratuit
                    </button>
                    <p>Oui j'ai que ça à faire :)</p>

                    <fieldset className="fieldset-center">
                        <legend className="fieldset-legend">liens cachés</legend>

                        <Link to="/tour/primo-arrivant">
                            <button>
                                Découvrir la Vie Associative
                            </button>
                        </Link>

                        <Link to="/authenticated/">
                            <button>AUthentification avec Keycloak</button>
                        </Link>
                        <button onClick={() => setDev(!dev)}>mode développeur</button>
                        <br/>
                    </fieldset>
                </div>
            </div>

            <div className="window easteregg-window" id="taskfailedsuccessfully" hidden={!haha}>
                <div className="title-bar" id="taskfailedsuccessfully-header">
                    <div className="title-bar-text">Alert</div>
                    <div className="title-bar-controls">
                        <button aria-label="Minimize"></button>
                        <button aria-label="Close" onClick={() => sa(false)}></button>
                    </div>
                </div>
                <div className="window-body">
                    <p>Task failed successfully</p>
                    <button onClick={() => sa(false)}>Ok</button>
                    &nbsp; &nbsp;
                    <button onClick={() => sa(false)}>Ok</button>
                </div>
            </div>
        </div>)
    } else
        return <Konami action={() => setEasteregg(true)}/>

}
