import React from "react";
import {Button, Card, Fade, Spinner} from "react-bootstrap";
import MemberComponent from "./member/MemberComponent";
import {handleAxiosError} from "../errors";

class AdhesionUserComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false}
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({show: true})
        }, 300);
    }

    render() {
        return (
            <div>

                <Fade in={this.state.show && this.props.adhesion_user != null}>
                    <Card className="bg-secondary text-white">
                        <Card.Header>Mon compte Adhésion</Card.Header>
                        <Card.Body>
                            {this.props.adhesion_user ? (<div>
                                <div>Prénom: <strong>{this.props.adhesion_user.first_name}</strong></div>
                                <div>Nom: <strong>{this.props.adhesion_user.last_name}</strong></div>
                                <div>Email: <strong>{this.props.adhesion_user.email}</strong></div>
                                <pre>
                                    <code>
                                        {JSON.stringify(this.props.adhesion_user)}
                                    </code>
                                </pre>
                            </div>) : ("")}
                        </Card.Body>
                    </Card>
                </Fade>
            </div>
        );
    }
}

function AccountComponent(props) {
    if (props.account) { //{"username":"jean.ribes@bde-insa-lyon.fr","firstName":"Jean","lastName":"Ribes","email":"jean@ribes.ovh","emailVerified":true,"attributes":{"locale":["fr"]}}
        return (<div>
            <Card className="bg-success text-white">
                <Card.Header>Mon compte SSO</Card.Header>
                <Card.Body>
                    <div>Prénom <strong>{props.account.firstName}</strong></div>
                    <div>Nom &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>{props.account.lastName}</strong></div>
                    <div>E-mail &nbsp;&nbsp;&nbsp;<strong>{props.account.email}</strong></div>
                    <br/>
                    <code>
                        <pre>
                            {JSON.stringify(props.account)}
                        </pre>
                    </code>
                    <Button href={props.keycloak.createAccountUrl()} target="_blank">Éditer</Button>
                </Card.Body>
            </Card>
        </div>)
    } else return <div>rien</div>
}

class ProfileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {adhesion_user: null, account: null, showAccount: false};
    }

    componentDidMount() {
        window.httpService.get('/me')
            .then((res) => {
                this.setState({adhesion_user: res.data, member: res.data.member});
                console.log(res.data);
                /*
                {"id":4,"member":null,"card":null,"last_login":"2020-04-20T16:33:12.864695+02:00","username":"jean@ribes.ovh","first_name":"Jean","last_name":"Ribes","email":"jean@ribes.ovh","is_active":true,"date_joined":"2020-04-20T16:01:45.521356+02:00","last_fetched_groups":"2020-04-21T10:36:46.595794+02:00","user_level":0,"groups":[],"user_permissions":[]}
                * */
            }).catch(err => handleAxiosError(err));
        try {
            this.props.keycloak.loadUserProfile().then(value => {
                this.setState({account: value});
                /*
                {"username":"jean.ribes@bde-insa-lyon.fr","firstName":"Jean","lastName":"Ribes","email":"jean@ribes.ovh","emailVerified":true,"attributes":{"locale":["fr"]}}
                 */
            }).catch(err => handleAxiosError(err));
        } catch (e) {

        }

    }

    render() {
        if (this.state.showAccount) {
            console.log("yes")
        }
        return (<div>

            {this.state.adhesion_user ?
                <div><br/>
                    <MemberComponent member={this.state.adhesion_user.member} account={this.state.account}
                                     httpService={window.httpService}/>
                </div> : <div>
                    <Spinner animation="border"/>
                    Chargement en cours...
                </div>}
            <br/>
            {this.state.keycloak !== null ?
                <AccountComponent account={this.state.account} keycloak={this.props.keycloak}
                                  showAccount={this.state.showAccount}/> : ""}
            {false ? <AdhesionUserComponent/> : ""}


            <br/>
            <Button variant="outline-secondary" onClick={() => {
                this.props.history.push('/tour/primo-arrivant');
                this.props.keycloak.logout();
            }}>
                Déconnexion
            </Button>
        </div>)
    }
}

export default ProfileComponent;

